@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.eot');
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff'),
        url('../fonts/Roboto-Light.ttf') format('truetype'),
        url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype'),
        url('../fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.eot');
    src: url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype'),
        url('../fonts/Roboto-Black.svg#Roboto-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.eot');
    src: url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff'),
        url('../fonts/Roboto-Medium.ttf') format('truetype'),
        url('../fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype'),
        url('../fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Light.eot');
    src: url('../fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Light.woff2') format('woff2'),
        url('../fonts/Montserrat-Light.woff') format('woff'),
        url('../fonts/Montserrat-Light.ttf') format('truetype'),
        url('../fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.eot');
    src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/Montserrat-Regular.woff') format('woff'),
        url('../fonts/Montserrat-Regular.ttf') format('truetype'),
        url('../fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.eot');
    src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/Montserrat-Bold.woff') format('woff'),
        url('../fonts/Montserrat-Bold.ttf') format('truetype'),
        url('../fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-SemiBold.eot');
    src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/Montserrat-SemiBold.woff') format('woff'),
        url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
        url('../fonts/Montserrat-SemiBold.svg#Montserrat-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;500;700;900&display=swap'); */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Montserrat', sans-serif;
    background-color: #000;
}

img {
    display: block;
    max-width: 100%;
}

ul {
    list-style: none;
}

p {
    margin: 10px 0;
}

body {
    margin-top: 90px;
}

a {
    text-decoration: none;
}

.wrapper {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
}

.section_inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

main.home {
    padding-top: 100vh;
}

header {
    padding: 0 80px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    align-items: center;
}

header .logo {
    display: inline-flex;
}

header .logo img {
    height: 45px;
}

header nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

header nav ul {
    display: flex;
    list-style: none;
    margin-left: auto;
}

header nav ul li {
    margin: 0 17px;
}

header nav ul li a {
    text-decoration: none;
    font-size: 14px;
    line-height: 1.5;
    color: #dedede;
    height: 90px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    transition: 0.2s;
}

header nav ul li a:hover {
    color: #0bcbfb !important;
}

header nav ul li.active-page a {
    color: #fff;
}

header nav .sidebar__toggle {
    margin-left: 17px;
    display: inline-flex;
    position: relative;
    height: 31px;
    align-items: center;
    cursor: pointer;
}

header nav .sidebar__toggle .toggle__icon {
    position: relative;
    display: block;
    width: 31px;
    height: 7px;
    background-size: 11px 1px, 11px 1px, 1px 0, 1px 0;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    animation: parallelBars 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    background-image: linear-gradient(to right, #b1b1b1 80%, transparent 20%),
        linear-gradient(to right, #b1b1b1 80%, transparent 20%),
        linear-gradient(to bottom, #b1b1b1 80%, transparent 20%),
        linear-gradient(to bottom, #b1b1b1 80%, transparent 20%);
}

header nav .sidebar__toggle:hover .toggle__icon {
    animation-play-state: running;
    background-image: linear-gradient(to right, #fff 80%, transparent 20%),
        linear-gradient(to right, #fff 80%, transparent 20%),
        linear-gradient(to bottom, #fff 80%, transparent 20%),
        linear-gradient(to bottom, #fff 80%, transparent 20%);
}

@keyframes parallelBars {
    0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0;
    }

    100% {
        background-position: 22px 0, -22px 100%, 0 -22px, 100% 22px;
    }
}

.has-dropdown-menu {
    position: relative;
}

.dropdown-menu::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    border-top: 1px solid transparent;
    -o-border-image: url(../images/dashed-line-h.svg) 100% 1 0 repeat;
    border-image: url(../images/dashed-line-h.svg) 100% 1 0 repeat;
    opacity: .55;
}

.has-dropdown-menu:hover .dropdown-menu {
    opacity: 1;
    visibility: visible;
}

.dropdown-menu {
    width: 205px;
    position: absolute;
    left: -37px;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
}

.dropdown-menu ul {
    padding: 5px;
    display: flex;
    flex-direction: column;
    background-color: #000;
}

.dropdown-menu ul li a {
    height: auto;
    margin: 9px 0;
    color: #ccc;
    font-size: 14px;
    line-height: 1.75em;
    font-weight: 300;
}

.sticky__hero::after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, .15);
    top: 0;
    left: 0;
    z-index: 0;
}

.sticky__hero {
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000;
    background-position: center;
    background-size: 100%;
}

.sticky__hero_inner {
    text-align: center;
    position: relative;
    z-index: 1;
    padding-top: 80px;
}

.sticky__hero video {
    position: absolute;
    z-index: 0;
}

.sticky__hero .hero__heading {
    color: #fff;
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 33px;
}

.sticky__hero .author_name {
    position: absolute;
    bottom: 15%;
    right: 80px;
    text-align: right;
    z-index: 1;
}

.sticky__hero .author_name span {
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    font-weight: 600;
    display: inline-flex;
}

.sticky__hero .author_name h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: -6px;
}

.css_btn.view__more {
    margin: 0 28px;
}

.css_btn {
    font-size: 11px;
    line-height: 2;
    text-transform: uppercase;
    padding: 12px 60px 12px 60px;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: transparent;
    border: none !important;
    background-size: 11px 1px, 11px 1px, 1px 11px, 1px 11px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    animation: css_btn_outline 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    background-image: linear-gradient(to right, #8e8e8e 80%, transparent 20%),
        linear-gradient(to right, #8e8e8e 80%, transparent 20%),
        linear-gradient(to bottom, #8e8e8e 80%, transparent 20%),
        linear-gradient(to bottom, #8e8e8e 80%, transparent 20%);
    transition: 0.2s;
    position: relative;
}

.css_btn:hover {
    font-weight: 700;
    background-image: linear-gradient(to right, #0bcbfb 80%, transparent 20%),
        linear-gradient(to right, #8ffca0 80%, transparent 20%),
        linear-gradient(to bottom, #0bcbfb 80%, transparent 20%),
        linear-gradient(to bottom, #8ffca0 80%, transparent 20%) !important;
    color: #8ffca0 !important;
    background-color: transparent;
    border: none !important;
    animation-play-state: running;
}

@keyframes css_btn_outline {
    0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0;
    }

    100% {
        background-position: 22px 0, -22px 100%, 0 -22px, 100% 22px;
    }
}

.video__sec {
    width: 100%;
    min-height: 100vh;
    position: relative;
    background-position: center center;
    background-size: cover;
    background-color: #333;
}

.meta__sec {
    min-height: 90vh;
    padding: 80px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
}

.meta__sec video {
    position: absolute;
    top: 0;
    left: 0;
}

/* .enter__meta.rtl {
    margin-left: auto;
} */
.enter__meta {
    font-size: 14px;
    padding: 20px 70px;
    font-weight: 600;
    line-height: 2em;
    background-image: linear-gradient(#8ffca0, #0bcbfb);
    background-size: cover;
}

.enter__meta:hover {
    background-size: 11px 1px, 11px 1px, 1px 11px, 1px 11px;
}

@keyframes css_btn_outline {
    0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0;
    }

    100% {
        background-position: 22px 0, -22px 100%, 0 -22px, 100% 22px;
    }
}

.video__sec img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.video__sec video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.contact__sec {
    position: relative;
    background-color: #000;
}

.contact__sec .section_inner {
    justify-content: space-between;
    align-items: center;
    padding: 60px 0px 50px 0px;
}

.contact__sec .leftBlock {
    width: 54%;
    padding-right: 8%;
}

.contact__sec .leftBlock .heading {
    font-size: 36px;
    line-height: 1.222;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}

.contact__sec .leftBlock p {
    font-size: 14px;
    line-height: 1.75;
    font-weight: 400;
    color: #D7D7D7;
    margin: 12px 0 0;
    font-family: 'Roboto';
}

.contact__form {
    margin-top: 50px;
}

/* .contact__form .form__row:not(:last-child) {
    margin-bottom: 12px;
} */
.contact__form .form__row {
    display: flex;
    margin: 0 -15px;
}

.contact__form .submit__row .input__field {
    flex-wrap: wrap;
}

.contact__form .input__field {
    width: 100%;
    display: flex;
    padding: 0 15px;
}

.contact__form .input__field textarea {
    margin-top: 12px;
    min-height: 91px;
}

.contact__form .input__field textarea:focus,
.contact__form .input__field input:focus {
    border-color: #fff;
}

.contact__form .input__field textarea,
.contact__form .input__field input {
    display: inline-flex;
    width: 100%;
    margin: 0 0 20px;
    padding: 5px 0;
    font-size: 14px;
    line-height: 21px;
    color: #d9d9d9;
    background-color: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid #6f6f6f;
    transition: color .2s ease-out, background-color .2s ease-out, border-color .2s ease-out;
}

.contact__form .input__field textarea::placeholder,
.contact__form .input__field input::placeholder {
    opacity: 1;
    font-family: 'Montserrat';
}

.contact__sec .leftBlock .submitBtn {
    padding: 12px 59px;
    font-family: 'Montserrat';
    margin-top: 20px;
}

.contact__sec .rightBlock {
    width: 46%;
}

.contact__sec .rightBlock img {
    width: 100%;
}

.back_to_top.show {
    visibility: visible;
    opacity: 1;
}

.back_to_top {
    visibility: hidden;
    opacity: 0;
    cursor: pointer;
    position: fixed;
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 30px;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: 0.3s;
    background-size: 15px 1px, 15px 1px, 1px 15px, 1px 15px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    animation: back_to_top 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    background-image: linear-gradient(to right, #8e8e8e 80%, transparent 20%), linear-gradient(to right, #8e8e8e 80%, transparent 20%), linear-gradient(to bottom, #8e8e8e 80%, transparent 20%), linear-gradient(to bottom, #8e8e8e 80%, transparent 20%);
}

.back_to_top:hover {
    color: #fff;
    background-image: linear-gradient(to right, #0bcbfb 80%, transparent 20%), linear-gradient(to right, #8ffca0 80%, transparent 20%), linear-gradient(to bottom, #0bcbfb 80%, transparent 20%), linear-gradient(to bottom, #8ffca0 80%, transparent 20%) !important;
    animation-play-state: running;
}

@keyframes back_to_top {
    0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0;
    }

    100% {
        background-position: 30px 0, -30px 100%, 0 -30px, 100% 30px;
    }
}

.galleryBx .galleryBx-content {
    flex-direction: column;
    display: none;
}

.galleryBx-title a {
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    line-height: 1.167;
    font-weight: 600;
    text-transform: uppercase;
}

.galleryBx-category-link,
.qodef-follow-info-category {
    color: #d9d9d9;
    font-size: 14px;
    line-height: 1.75;
    font-weight: 300;
    text-decoration: none;
}

.galleryMain {
    position: relative;
    z-index: 1;
    min-height: 100vh;
    background-color: #000;
    padding: 2% 0% 3% 0%;
    width: 100%;
}

.galleryMain-inner {
    display: flex;
    flex-wrap: wrap;
    margin: -10px -10px;
}

.galleryBx {
    margin: 30px;
    width: calc(50% - 60px);
}

.galleryBx .galleryBx-image {
    overflow: hidden;
    max-height: 666.66px;
    height: 100%;
}



.galleryBx .galleryBx-image video,
.galleryBx .galleryBx-image img {
    transition: all .2s ease-out;
    width: 100%;
}

.galleryBx:hover .galleryBx-image video,
.galleryBx:hover .galleryBx-image img {
    filter: blur(5px);
}




.qodef-follow-info-holder {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    pointer-events: none;
    visibility: hidden;
    transition: transform .3 ease
}

.qodef-follow-info-holder.qodef-right {
    text-align: right;
    transform: translateX(-100%)
}

.qodef-follow-info-holder .qodef-follow-info-inner {
    position: relative;
    display: inline-block;
    vertical-align: middle
}

.qodef-follow-info-holder .qodef-follow-info-title {
    display: block;
    position: relative;
    color: #fff;
    text-transform: uppercase;
    white-space: nowrap;
    z-index: 8;
    opacity: 0;
    transform: translateY(20px);
    will-change: transform;
    transition: 0s
}

.qodef-follow-info-holder .qodef-follow-info-title .qodef-e-title {
    margin: 0
}

.qodef-follow-info-holder .qodef-follow-info-title a {
    color: #fff
}

.qodef-follow-info-holder .qodef-follow-info-category {
    display: block;
    white-space: nowrap;
    z-index: 9;
    opacity: 0;
    transform: translateY(20px);
    will-change: transform;
    transition: 0s
}

.qodef-follow-info-holder .qodef-follow-info-category a:not(:last-child):after {
    content: ", "
}

.qodef-follow-info-holder .qodef-follow-info-category a {
    color: #d9d9d9
}

.qodef-follow-info-holder.qodef-is-active {
    visibility: visible
}

.qodef-follow-info-holder.qodef-is-active .qodef-follow-info-title {
    opacity: 1;
    transform: translateY(0);
    transition: transform .6s cubic-bezier(.22, .61, .36, 1) .1s, opacity .3s cubic-bezier(.33, .02, 0, .93) .1s
}

.qodef-follow-info-holder.qodef-is-active .qodef-follow-info-category {
    opacity: 1;
    transform: translateY(0);
    transition: transform .6s cubic-bezier(.22, .61, .36, 1) .2s, opacity .3s cubic-bezier(.33, .02, 0, .93) .2s
}

.sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 50px);
    max-width: 437px;
    height: 100%;
    padding: 120px 70px 35px;
    background-color: #000;
    z-index: 9999;
    transition: all .6s cubic-bezier(.77, 0, .175, 1);
    background-size: 0 1px, 0 1px, 1px 11px, 1px 0;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    animation: sidebar_left_Border 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    background-image: linear-gradient(to right, #666 80%, transparent 20%), linear-gradient(to right, #666 80%, transparent 20%), linear-gradient(to bottom, #666 80%, transparent 20%), linear-gradient(to bottom, #666 80%, transparent 20%);
    transform: translateX(100%);
}

@keyframes sidebar_left_Border {
    0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0;
    }

    100% {
        background-position: 22px 0, -22px 100%, 0 -22px, 100% 22px;
    }
}

.sidebar_active .sidebar {
    transform: translateX(0%);
    animation-play-state: running;
}

.sidebar_inner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidebar .site__logo {
    margin-top: 37px;
    margin-bottom: 26px;
    display: inline-flex;
}

.sidebar .site__logo img {
    width: 203px;
    height: 60px;
    object-fit: contain;
    object-position: center;
}

.sidebar .desc {
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    font-weight: 300;
    margin-top: 15px;
    margin-bottom: 36px;
}

.social_icons {
    display: flex;
    margin: 0 -10px 26px;
}

.social_icons li {
    margin: 10px;
    list-style: none;
}

.social_icons li a {
    text-decoration: none;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
}

.sidebar .copyright {
    margin-top: auto;
    font-size: 14px;
    line-height: 25px;
    color: #d9d9d9;
    font-weight: 300;
}

.sidebar__cover {
    cursor: url(../images/close.png), url(../images/close.png) 10 10, not-allowed;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    z-index: 0;
    background: rgba(0, 0, 0, .3);
    transition: opacity .3s ease-in-out, z-index .3s ease-in-out;
}

.sidebar_active .sidebar__cover {
    visibility: visible;
    z-index: 1;
}

.about__main {
    position: relative;
    z-index: 1;
    margin-top: -90px;
    padding: 180px 0px 150px 0px;
    background-color: #000;
}

.about_header {
    display: flex;
    padding: 0% 5%;
}

.about_header .left__panel {
    position: relative;
    width: 40%;
    padding: 150px 55px;
    background-position: center center;
    background-size: cover;
    min-height: 600px;
}

.about_header .left__panel.about__gf-bg {
    background-image: url("../images/abour.gif");
}

.about_header .left__panel .heading {
    position: relative;
    font-size: 80px;
    line-height: 1;
    font-weight: 900;
    color: #fff;
}

.about_header .right__panel {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 27px;
}

.about_header .right__panel .desc:not(:last-child) {
    margin-bottom: 20px;
}

/* .about_header .right__panel .desc:not(:first-child) {
    padding-top: 15px;
} */
.about_header .right__panel .desc p {
    font-size: 15px;
    text-align: justify;
    line-height: 1.6;
    color: #fff;
    font-weight: 300;
    margin: 10px 0;
}

.about_header .right__panel .desc_600 p {
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    margin: 0;
}

.about_header .right__panel .desc.dark p {
    font-weight: 500;
}

.artist__info_sec {
    width: 100%;
    padding: 5%;
    padding-bottom: 0;
}

.artist__info_sec .wrapper {
    max-width: 100%;
}

.artist__info_sec .heading {
    font-size: 30px !important;
    line-height: 1.267 !important;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 !important;
}

.artist__info_sec .desc {
    font-size: 14px;
    line-height: 1.75;
    color: #d9d9d9;
    font-weight: 300;
    margin: 5px 0 0;
}

.artist__info_main {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 75px;
    margin-top: 60px;
}

.artist__info_main .artist__info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    --image: 120px;
    --space: 28px;
}

.artist__info_main .artist__image {
    width: var(--image);
    height: var(--image);
    margin-right: var(--space);
    padding: 15px;
    background-size: 11px 1px, 11px 1px, 1px 11px, 1px 11px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    animation: artist__image 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: paused;
    background-image: linear-gradient(to right, #8e8e8e 80%, transparent 20%), linear-gradient(to right, #8e8e8e 80%, transparent 20%), linear-gradient(to bottom, #8e8e8e 80%, transparent 20%), linear-gradient(to bottom, #8e8e8e 80%, transparent 20%);
}

.artist__info_main .artist__image:hover {
    animation-play-state: running
}

@keyframes artist__image {
    0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0
    }

    100% {
        background-position: 22px 0, -22px 100%, 0 -22px, 100% 22px
    }
}

.artist__info_main .artist__info .text {
    /* width: calc(100% - var(--image) - var(--space)); */
    width: 100%;
    padding-right: 20px;
}

.artist__info_main .artist__info .artist__title a,
.artist__info_main .artist__info .artist__title {
    font-size: 24px;
    line-height: 28px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 30px;
}

.artist__info_main .artist__info .artist__desc {
    font-size: 14px;
    line-height: 25px;
    color: #d9d9d9;
    font-weight: 300;
    margin: 5px 0 0;
}

.swiper {
    width: 100%;
    height: 100%;
}

.author__slider_main {
    margin-top: 100px;
}

.author__slider_main .wrapper {
    position: relative;
    max-width: 100%;
    padding: 0 5% 0 11%;
}

.author__slider_main .author__quote__mark {
    position: absolute;
    left: calc(11% + (-74px - 30px));
    top: 0;
}

.author__slider .swiper-pagination {
    text-align: left;
    position: relative;
    bottom: 0 !important;
}

.author__slider .swiper-pagination-bullet.swiper-pagination-bullet-active,
.author__slider .swiper-pagination-bullet:hover {
    color: #fff;
}

.author__slider .swiper-pagination-bullet {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    background-color: transparent;
    width: auto;
    height: auto;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    color: #8e8e8e;
    margin: 0;
    margin-right: 32px !important;
    opacity: 1;
    transition: 0.3s;
}

.author__slider .swiper-pagination-bullet svg {
    margin-left: 7px;
}

.author__slider .swiper-slide {
    display: flex;
    flex-direction: column;
    margin-bottom: 36px;
}

.author__slider .text {
    font-size: 18px;
    line-height: 29px;
    color: #fff;
    font-weight: 300;
    margin: 0 0 38px;
}

.author__slider .author__name {
    font-size: 15px;
    line-height: 23px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.author__slider .author__job {
    font-size: 14px;
    line-height: 25px;
    color: #fff;
    font-weight: 300;
}

.partner__main {
    padding: 130px 0 100px;
    margin-top: 0;
}

.partner__sec {
    width: 100%;
    padding: 0% 8%;
}

.partner__sec_inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: -18px;
}

.partner__sec .partner {
    width: calc(20% - 40px);
    margin: 20px;
    transition: .15s;
    display: inline-flex;
}

.partner__sec .partner a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    padding: 10px;
    width: 100%;
}

.partner__sec .partner a img {
    width: 100%;
    transition: 0.5s;
    object-fit: contain;
}

.partner__sec .partner:hover a img {
    transform: translateY(-120%);
}

.partner__sec .partner:hover .partner_details {
    transform: translateY(0%);
    opacity: 1;
}

.partner__sec .partner_details {
    transform: translateY(100%);
    opacity: 0;
    position: absolute;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
}

.partner__sec .partner_details .partner_title {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.partner__sec .partner_details .partner_desc {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    font-weight: 300;
    transition: 0.2s;
}

footer {
    position: relative;
    background-color: #000;
}

footer .footer__bottom::before,
footer::before,
footer::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: .35;
}

footer .footer__bottom::before {
    border-top: 2px solid #fff;
    border-image: none;
}

footer::before {
    display: none;
}

footer::after {
    top: 10px;
}

footer .footer__top {
    padding: 50px;
    padding-top: calc(50px + 11px);
    padding-bottom: 20px;
    display: flex;
    margin: 0 -15px;
}

footer .footer__block {
    width: calc(33.33% - 30px);
    margin: 0 15px;
}

footer .footer__block .heading {
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

footer .footer__logo {
    display: inline-flex;
}

footer .footer__logo img {
    width: 153px;
    height: 45px;
    object-fit: contain;
    object-position: center;
}

footer .quick__links,
footer .contact__links {
    display: flex;
    flex-direction: column;
}

footer .quick__links {
    margin-bottom: 30px;
}

footer .contact__link:not(:last-child) {
    margin-bottom: 8px;
}

footer .quick__links li {
    margin-bottom: 8px;
}

footer .contact__link,
footer .quick__links a,
footer .contact__link a {
    text-decoration: none;
    font-size: 14px;
    line-height: 25px;
    color: #d9d9d9;
    font-weight: 300;
    transition: 0.2s;
}

footer .contact__link a:hover {
    color: #fff;
}

footer .footer__bottom {
    position: relative;
}

footer .footer__bottom .wrapper {
    max-width: 100% !important;
}

footer .footer__bottom_inner {
    padding: 20px 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

footer .footer__bottom .copyright,
footer .footer__bottom .caption {
    font-size: 14px;
    line-height: 25px;
    color: #bababa;
    font-weight: 300;
}

footer .social_icons {
    margin-bottom: -10px;
    justify-content: flex-end;
}

.fix__header header {
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    width: 100%;
    background-color: #000;
    z-index: 100;
    animation: fix__header .5s;
}

@keyframes fix__header {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0);
    }
}

.fix__header header nav ul li a {
    height: auto;
}

.fix__header header nav .sidebar__toggle {
    display: none;
}

.fix__header .dropdown-menu {
    top: 45px;
}

.collection__slider_main {
    min-height: calc(100vh - 250px);
    display: flex;
    align-items: center;
}

.collection__slider .swiper-wrapper {
    padding: 150px 0 100px;
}

.collection__slider_main .wrapper {
    width: calc(100% - 250px) !important;
    position: relative;
    max-width: 1300px;
}

.collection__slider .slide__img {
    width: 100%;
    display: inline-flex;
    position: relative;
    height: 510px;
    transition: transform .4s cubic-bezier(.29, .6, .25, 1);
}

.collection__slider .slide__img video,
.collection__slider .slide__img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.collection__slider .swiper-slide .slide__img {
    transition: 1.2s cubic-bezier(.5, .11, 0, .93);
}

.collection__slider .swiper-slide.swiper-slide-active .slide__img {
    transform: scale(1.5);
}

@media (min-width:768px) {
    .collection__slider .swiper-slide .slide__img {
        transition: 1.2s cubic-bezier(.5, .11, 0, .93) .3s;
    }

    .collection__slider .swiper-slide.swiper-slide-active .slide__img {
        transform: scale(1.5);
        width: 400px;
        margin-left: -90px;
    }
}

/* .collection__slider .swiper-slide.swiper-slide-prev .slide__img,
.collection__slider .swiper-slide.swiper-slide-next .slide__img {
    transform: scale(1.2);
    transition: transform 1.2s cubic-bezier(.5,.11,0,.93) ;
} */
.collection__slider .slide__img:hover:after {
    opacity: 1;
    transition: opacity 1s cubic-bezier(.33, .02, 0, .93);
}

.collection__slider .slide__img::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30%;
    background: linear-gradient(to top, #000 0, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
    bottom: -1px;
    opacity: 0;
    transition: 0s;
}

.collection__slider .swiper-slide.swiper-slide-active {
    z-index: 10;
}

.collection__slider .swiper-slide.swiper-slide-prev,
.collection__slider .swiper-slide.swiper-slide-next {
    z-index: 9;
}

.collection__slider_main .swiper-button-next::after,
.collection__slider_main .swiper-button-prev::after {
    display: none;
}

.collection__slider_main .swiper-button-prev .icon svg,
.collection__slider_main .swiper-button-next .icon svg {
    width: 32px;
    fill: none;
    stroke: #fff;
    stroke-width: 2px;
    position: relative;
    top: 2px;
    transition: 0.3s;
}

.collection__slider_main .swiper-button-prev:hover .icon svg,
.collection__slider_main .swiper-button-next:hover .icon svg {
    stroke: #b1b1b1;
}

.collection__slider_main .swiper-button-prev .text,
.collection__slider_main .swiper-button-next .text {
    font-size: 11px;
    line-height: 25px;
    color: #fff;
    font-weight: 400;
    text-transform: uppercase;
    transition: 0.3s;
}

.collection__slider_main .swiper-button-prev:hover .text,
.collection__slider_main .swiper-button-next:hover .text {
    color: #b1b1b1;
}

.collection__slider_main .swiper-button-prev {
    transform: translateX(-99px);
}

.collection__slider_main .swiper-button-next {
    transform: translateX(99px);
}

.collection__slider_main .swiper-button-prev,
.collection__slider_main .swiper-button-next {
    display: flex;
    align-items: center;
}

.collection__slider_main .swiper-button-next {
    flex-direction: row-reverse;
}

.collection__slider .slide__content {
    position: relative;
    top: -20px;
}

.collection__slider .slide__content .title {
    font-size: 15px;
    line-height: 1.533em;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.collection__slider .slide__content .desc {
    font-size: 14px;
    line-height: 1.75em;
    font-weight: 600;
    color: #fff;
    margin-top: 0;
}

.collection__slider .slide__content .title,
.collection__slider .slide__content .desc {
    opacity: 0;
    transform: translateY(10px);
    transition: 0s;
    will-change: transform;
}

.collection__slider .swiper-slide.swiper-slide-active:hover .slide__content .title,
.collection__slider .swiper-slide.swiper-slide-active:hover .slide__content .desc {
    opacity: 1;
    transform: translateY(0);
    transition: transform .6s cubic-bezier(.22, .61, .36, 1) .1s, opacity .3s cubic-bezier(.33, .02, 0, .93) .1s;
}

.collection__slider .swiper-slide.swiper-slide-active:hover .slide__content .desc {
    transition: transform .6s cubic-bezier(.22, .61, .36, 1) .2s, opacity .3s cubic-bezier(.33, .02, 0, .93) .2s;
}

footer.no_borders::before,
footer.no_borders::after,
footer.no_borders .footer__bottom::before {
    display: none;
}

.counter__div {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 30px 0px;
}

.counter__div .wrapper {
    max-width: 1140px;
    padding: 0 30px;
}

.counter_main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px;
}

.counter__box {
    width: calc(33.33% - 20px);
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.counter__box .number span {
    font-size: clamp(50px, 5vw, 75px);
    line-height: 1;
    font-weight: 600;
    color: #fff;
}

.counter__box span.text {
    font-size: 15px;
    line-height: 1.533;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
}

.section {
    margin-top: 100px;
    margin-bottom: 20px;
    padding: 0% 5%;
}

.section .wrapper {
    max-width: 100%;
}

.section__inner {
    padding: 10px;
}

.section__inner .heading {
    font-size: 36px;
    line-height: 1.23;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.section__inner .small__heading {
    font-size: 15px;
    line-height: 1.533;
    line-height: 23px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    margin: 25px 0;
}

.masonary__sec {
    padding: 30px 30px 30px 30px;
}

.masonary__inner {
    padding: 10px;
    margin: 0 -10px;
}

.masonary__sec .grid-item {
    width: 20%;
    padding: 0 10px;
    margin: 0 0 20px;
    transition: 0.5s;
}

.masonary__sec .grid-item video {
    width: 100%;
}

.masonary__sec .grid__content {
    padding: 10px;
    width: 100%;
    max-width: 375px;
    text-align: center;
    margin-top: 20px;
}

.masonary__sec .grid__content .title_name {
    font-size: 20px;
    line-height: 1.533em;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.masonary__sec .grid__content .artist_name {
    font-size: 15px;
    line-height: 1.533em;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 10px;
}

.masonary__sec .grid__content .artwork_link {
    font-size: 14px;
    line-height: 1.75em;
    font-weight: 300;
    color: #fff;
}

/* loader css STARTS */

#page-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2000;
}

#page-loader .page-loader-inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #fff;
}

#page-loader .page-loader-inner {
    background-color: #000000;
    color: #f9f9f9;
}

#page-loader .loader-spinner {
    position: relative;
}

#page-loader.qodef-layout--rotating-cubes .loader-cubes {
    position: relative;
    width: 32px;
    height: 32px;
    margin: -16px 0 0 -16px;
}

#page-loader.qodef-layout--rotating-cubes .loader-cube {
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: currentColor;
    animation: qode-rotating-cubes 1.8s infinite ease-in-out;
}

#page-loader.qodef-layout--rotating-cubes .loader-cube.loader-cube--2 {
    animation-delay: -.9s;
}

@keyframes qode-rotating-cubes {
    25% {
        transform: translateX(42px) rotate(-90deg) scale(.5)
    }

    50% {
        transform: translateX(42px) translateY(42px) rotate(-179deg)
    }

    50.1% {
        transform: translateX(42px) translateY(42px) rotate(-180deg)
    }

    75% {
        transform: translateX(0) translateY(42px) rotate(-270deg) scale(.5)
    }

    100% {
        transform: rotate(-360deg)
    }
}

/* loader css ENDS */
div.g-recaptcha {
    display: block;
    width: 100%;
}

.portfolio__inner {
    display: flex;
    align-items: flex-start;
    padding: 120px 0px 120px 0px;
    margin: 0 -15px;
}

.portfolio__inner .left__panel {
    width: 66.66667%;
    margin: 0 15px;
    display: flex;
}

.portfolio__inner .image_div video,
.portfolio__inner .image_div img {
    width: 100%;
}

.portfolio__inner .image_div {
    margin-right: 50px;
    display: flex;
}

.portfolio__inner .image_div a {
    display: inline-flex;
}

.portfolio__inner .right__panel {
    width: 33.33333%;
    margin: 0 15px;
}

.portfolio__inner .right__panel .heading {
    color: #fff;
    font-size: 36px;
    line-height: 1.222;
    margin: 0 0 13px;
    font-weight: 600;
    text-transform: uppercase;
    word-wrap: break-word;
}

.portfolio__inner .right__panel .desc {
    margin-bottom: 25px;
}

.portfolio__inner .right__panel .desc p {
    font-size: 14px;
    line-height: 1.75;
    font-weight: 300;
    color: #d9d9d9;
}

.portfolio__inner .right__panel .meta__info {
    margin-top: 51px;
    font-size: 14px;
    line-height: 1.75em;
    font-weight: 300;
    color: #d9d9d9;
}

.portfolio__inner .right__panel .meta__info span {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    text-transform: uppercase;
}

.masonary__sec .lightbox-content video,
.masonary__sec .lightbox-content img {
    width: 100%;
    height: calc(100% - 140px);
    object-fit: contain;
    margin-top: -50px;
}

.custom-lightbox {
    cursor: pointer;
    display: flex;
    overflow: hidden;
    position: relative;
}

.custom-lightbox::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: inset 0 0 0 0px #000;
    transition: .5s;
}

.custom-lightbox:hover:after {
    background-color: #0009;
    box-shadow: inset 0 0 0 15px #000;
}

.custom-lightbox img {
    width: 100%;
    height: 100%;
    transition: .5s;
}

.custom-lightbox:hover>img {
    transform: scale(1.3);
}

.lightbox-content {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #000;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    z-index: 100000;
    padding: 50px;
    transition: all .6s cubic-bezier(.77, 0, .175, 1);
    transform-origin: center;
    opacity: 0;
}

.showlight .lightbox-content {
    transform: scale(1);
    opacity: 1;
}

.close_light {
    width: 50px;
    height: 50px;
    position: relative;
    top: -25px;
    left: 50%;
    font-size: 20px;
    line-height: 1.1;
    color: transparent;
    text-transform: uppercase;
    background-image: url(../images/close.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    cursor: pointer;
    background-color: #000;
    border-radius: 50%;
    box-shadow: 0 0 10px -1px #fff;
    transition: all .6s 1s cubic-bezier(.77, 0, .175, 1);
}

.showlight .close_light {
    animation: close_light 1s .1s;
}

@keyframes close_light {
    0% {
        top: -100px;
    }

    100% {
        top: -25px;
    }
}

.masonary_nav {
    position: absolute;
    --nav: 30px;
    width: var(--nav);
    height: var(--nav);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
}

.masonary_nav svg {
    width: 100% !important;
    height: 100% !important;
}

.pre_pop {
    left: 20px;
}

.next_pop {
    right: 20px;
}

.video__sec .author_name {
    position: absolute;
    bottom: 15%;
    right: 80px;
    text-align: right;
    z-index: 1;
}

.video__sec .author_name h2 {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: -6px;
}

.video__sec .author_name span {
    color: #fff;
    display: inline-flex;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.533;
    text-transform: uppercase;
}

.about__main .heading {
    font-size: 35px;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-top: 10px;
    margin-bottom: calc(10px + 20px + 10px + 20px);
    position: relative;
}

.about__main .heading.styled {
    background: linear-gradient(to right, #8ffca0, #0bcbfb);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Montserrat", Sans-serif;
    font-weight: 700 !important;
}

.about__main .heading.styled::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -40px;
    height: 10px;
    width: 100px;
    background-image: linear-gradient(to right, #8ffca0, #0bcbfb);
}

.collection_page {
    padding: 40px !important;
    padding-bottom: 10px !important;
    margin-top: 0;
}

.collection_page .about_header {
    align-items: stretch;
}

.collection_page .left__panel {
    padding: 0 !important;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.collection_page .right__panel {
    padding: 0 !important;
    width: 50%;
    position: relative;
}

.collection_page .right__panel img {
    position: absolute;
    top: -26px;
    right: 0;
    height: 100%;
}

.collection_page .desc p {
    color: #fff;
    font-size: 15px;
    line-height: 1.533;
    font-weight: 300;
    margin: 25px 0;
}

.collection_page .heading {
    font-size: 29px !important;
    line-height: 1 !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
}

.collection_page .small_heading a::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    right: 0;
    background: linear-gradient(to right, #fff, #fff);
    transition: all .6s cubic-bezier(.77, 0, .175, 1);
}

.collection_page .small_heading a:hover:before {
    width: 100%;
    left: 0;
}

.collection_page .small_heading a,
.collection_page .small_heading {
    color: #fff;
    font-size: 18px;
    line-height: 1.444;
    text-transform: uppercase;
    margin: 25px 0;
    font-weight: 700 !important;
    position: relative;
    background: linear-gradient(to right, #8ffca0, #0bcbfb);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.collection_page .small_heading.styled {
    background: linear-gradient(to right, #8ffca0, #0bcbfb);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sound_toggle {
    --sound_toggle: 25px;
    width: var(--sound_toggle);
    height: var(--sound_toggle);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 50px;
    right: 50px;
    cursor: pointer;
    z-index: 100;
    background-color: #000;
    border-radius: 50%;
    box-shadow: 0 0 0 10px #000;
}

.sound_toggle::after {
    content: '';
    position: absolute;
    top: 0;
    left: 1px;
    width: 140%;
    border-radius: 5px;
    height: 3px;
    background-color: #fff;
    transform-origin: top left;
    transform: rotate(45deg);
    transition: 0.3s;
    box-shadow: 0 0 0 2px #000;
}

.sound_toggle.unmute:after {
    width: 0%;
}

.sound_toggle svg {
    width: 100% !important;
    height: 100%;
}

.portfolio__main .artist_name {
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
    font-size: 15px;
    line-height: 1.533;
    margin: 25px 0;
}

.page_links {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.page_links a {
    margin: 10px;
    width: 300px;
}

.click_surface {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
}