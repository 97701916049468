@media (max-width:1366px) {
    .wrapper {
        max-width: 1100px !important;
    }
    .galleryMain {
        padding-top: 4%;
    }
    .galleryMain-inner {
        margin: -40px -10px;
    }
    .galleryBx {
        margin: 40px;
        width: calc(25% - 80px);
    }
    .artist__info_main {
        grid-template-columns: repeat(3,1fr);
    }
    .portfolio__inner {
      flex-wrap: wrap;
      padding-left: 20px;
      padding-top: 60px;
      padding-right: 20px;
    }
    .partner__sec .partner {
        width: calc(25% - 40px);
    }
    .masonary__sec .grid-item {
        width: 25%;
    }
}
@media (min-width:1025px) {
    .desktop_logo {display: block;}
    .respo_logo {display: none;}
}
@media (max-width:1024px) {
    .wrapper {
        max-width: 768px;
    }
    body {
        margin-top: 70px;
    }
    .desktop_logo {display: none;}
    .respo_logo {display: block;}
    .fix__header header nav .sidebar__toggle {display: inline-flex;}
    .collection__slider_main {
        padding: 0% 6%;
    }
    header {
        padding: 0 35px;
        height: 70px;
    }
    header nav ul {
        position: fixed;
        visibility: hidden;
        left: 30px;
        top: -100%;
        max-width: 768px;
        margin: 0 auto;
        flex-direction: column;
        display: flex;
        z-index: 1004;
        transition: all .6s cubic-bezier(.77,0,.175,1);
    }
    .sidebar_active header nav ul {
        visibility: visible;
        top: 90px;
    }
    header .logo img {
        height: 40px;
        padding: 0px 10px;
    }
    .sticky__hero {
        background-position: 53%;
        background-size: cover;
    }
    .galleryMain {
        padding: 20% 0;
    }
    .galleryMain-inner {
        margin: -10px 10px;
    }
    .galleryBx {
        margin: 20px;
        width: calc(50% - 40px);
    }
    .video__sec video {
        height: 100vh;
        object-fit: cover;
    }
    .contact__sec .section_inner {
        width: calc(100% - 34px);
        margin: 0 auto;
    }
    .contact__form .form__row {
        flex-wrap: wrap;
    }
    .contact__sec .leftBlock {
        padding-right: 40px;
        width: 50%;
    }
    .contact__sec .rightBlock {
        width: 50%;
    }
    .about__main {
        margin-top: 0;
        padding-top: 110px;
    }
    .about__main .heading {
        font-size: 28px !important;
    }
    .artist__info_sec .heading {
        font-size: 25px !important;
    }
    .artist__info_main .artist__info .artist__title a, .artist__info_main .artist__info .artist__title {
        font-size: 20px !important;
    }
    .about_header {
        flex-wrap: wrap;
        padding-top: 0;
    }
    .about_header .left__panel {
        width: 100%;
    }
    .about_header .left__panel .heading {
        font-size: 45px;
        line-height: 55px;
        padding-right: 100px;
    }
    .about_header .right__panel {
        width: 100%;
        padding: 50px 0px 0px;
    }
    .collection_page .right__panel img {
        position: unset;
    }
    .artist__info_main {
        grid-template-columns: repeat(2,1fr);
    }
    .author__slider_main {
        margin-top: 150px;
    }
    .author__slider_main .author__quote__mark {
        left: 0;
        top: -100px;
    }
    .partner__sec {
        padding: 0% 4%;
    }
    .partner__sec .partner {
        width: calc(33.33% - 40px);
    }
    footer .footer__top {
        flex-wrap: wrap;
    }
    .masonary__sec .grid-item {
        width:33.33%;
    }
    .sidebar_active .sidebar {
        transform: translateX(100%) !important;
        z-index: 10;
    }
    .sidebar__cover {
        height: 0;
        transition: all .6s cubic-bezier(.77,0,.175,1);
    }
    .sidebar_active .sidebar__cover {
        background-color: rgba(0,0,0,1);
        height: 100%;
        z-index: 10;
    /*  background-image: url(/assets/images/main__logo.png);
        background-size: 150px;
        background-position: calc(100% - 50px) calc(100% - 50px);
        background-repeat: no-repeat; */
    }
    header nav ul li:not(:last-child) {
        margin-bottom: 10px;
    }
    header nav ul li a {
        line-height: 1.533em;
        color: #dedede;
        font-size: 14px;
        font-weight: 400;
        height: auto;
        text-transform: capitalize;
    }
    .partner__main {
        padding: 50px 0;
    }
    .counter__box {
        width:100%;
        max-width: 255px;
        margin: 25px;
    }
    .portfolio__inner .left__panel {
      width: 100%;
    }
    .portfolio__inner .image_div {
        margin-right: 0;
    }
    .portfolio__inner .right__panel {
      width: 100%;
      padding-top: 50px;
    }
    .collection__slider_main .wrapper{
        width: calc(100% - 100px) !important;
    }
}
@media (max-width:767px) {
    .wrapper {
        max-width: 82%;
    }
    footer .footer__bottom_inner {
        flex-wrap: wrap;
    }
    .collection__slider_main {
        padding: 0 10vw;
    }
    .collection__slider .swiper-slide.swiper-slide-prev .slide__img,
    .collection__slider .swiper-slide.swiper-slide-next .slide__img,
    .collection__slider .swiper-slide.swiper-slide-active .slide__img {
        transform: scale(1);
    }
    .sticky__hero {
        padding: 0 50px;
        text-align: center;
    }
    .galleryMain {
        padding: 40px 0px;
    }
    .galleryMain-inner {
        margin: 0;
    }
    .galleryBx {
        width: 100%;
    }
    .contact__sec .leftBlock {
        padding-right: 0;
        width: 100%;
    }
    .contact__sec .rightBlock {
        width: 100%;
        margin-top: 75px;
    }
    .contact__sec .rightBlock img {
        width: 100%;
        object-fit: cover;
    }
    .video__sec {
        min-height: 170px;
    }
    .video__sec video {
        height: 250px;
    }
    .contact__sec .section_inner {
        padding: 80px 15px;
        width: 100%;
    }
    footer .footer__block {
        width: 100%;
        margin-bottom: 30px;
    }
    footer .footer__logo {
        margin: 0 auto;
        margin-bottom: 0;
    }
    footer .right__box {
        margin: 0 auto;
    }
    .partner__sec .partner {
        width: calc(50% - 40px);
    }
    .artist__info_main {
        grid-template-columns: repeat(1,1fr);
    }
    .artist__info_main .artist__info {
        padding-right: 28px;
    }
    .about_header .right__panel {
        padding: 25px 20px 0px;
    }
    .about_header {
        padding: 50px 0px;
    }
    .author__slider_main {
        margin-top: 100px;
    }
    .masonary__sec .grid-item {
        width:50%;
    }
    .css_btn.view__more {
        margin:5px 20px;
    }
    .sticky__hero .hero__heading {
        font-size: 24px;
        line-height: 1.3;
    }
    .sound_toggle {
        bottom: 20px;
        right: 20px;
    }
    footer .social_icons {
        justify-content: center;
        margin: 10px -10px 0;
    }
    .sticky__hero .author_name h2,
    .video__sec .author_name h2 {
        font-size: 18px;
        line-height: 1.7;
    }
    .sticky__hero .author_name span,
    .video__sec .author_name span {
        font-size: 13px;
    }
    .collection__slider .swiper-wrapper {
        padding: 50px 0 0px;
    }
    .collection__slider .slide__content {
        top: -100px;
        left: 20px;
    }
    .collection_page .heading {
        padding-right: 0 !important;
        font-size: 20px !important;
        line-height: 1.3 !important;
    }
    .collection_page .desc p {
        font-size: 14px !important;
    }
}
@media (max-width:600px) {
    .partner__sec .partner {
        width: calc(100% - 40px);
    }
    .masonary__sec .grid-item {
        width: 100%;
    }
}